import React from "react"
import Layout from "../layout/layout"

const Privacy = () => {
  const center = {
    textAlign: "center",
  }
  const noMargin = {
    marginLeft: "0px",
  }
  const abc = {
    listStyleType: "lower-alpha",
  }

  return (
    <div className="terms">
      <Layout title="Privacy Policy" description="Privacy Policy">
        <div className="terms__container layout-container">
          <h1 style={center}>Summit Institute Privacy Policy</h1>
          <div style={center}>Last updated July 2021</div>
          <div style={center} className="terms__info">
            Summit Institute, proudly owned by Learn Plus Limited, and its employees,
            officers and agents (<b>“we”</b>, <b>“us”</b>, <b>“our”</b> or{" "}
            <b>“Summit Institute”</b>) is fully committed to the protection of privacy
            at all times. This Privacy Policy describes the way in which we may
            collect and use your personal information upon you visiting
            https://summitinstitute.ac.nz or related domains (<b>“Site”</b>) or using
            our software or services hosted on the Site (<b>“Services”</b>).
          </div>

          <div style={center} className="terms__info">
            This Privacy Policy applies to any person who visits our Site or
            uses our Services. If you do not consent to the collection, use,
            disclosure, storage and processing of your personal information in
            accordance with this Privacy Policy, please do not access our Site
            or use any of our Services.
          </div>
          <h4 className="terms__header" style={noMargin}>
            Definitions
          </h4>
          <div>
            The following terms as used in this Privacy Policy have the
            following meanings:
          </div>
          <div className="terms__info">
            <b>“Personal information”</b> means any information about an
            identifiable individual, whether or not that information identifies
            that person directly or indirectly (e.g. by reference to an
            identifier such as a name, an identification number, an online
            identifier or to one or more factors specific to the physical,
            physiological, genetic, mental, economic, cultural or social
            identity of that person).
          </div>
          <h4 style={noMargin} className="terms__header">
            How we collect your personal information
          </h4>
          <div>
            We collect your personal information from you and others in the
            following circumstances
          </div>
          <ol>
            <li className="terms__info">
              <b>When you visit our Site.</b> We collect information relating to
              your use of the Site and the content you access. This may include
              your internet protocol (IP) address used to connect your computer
              to the internet, location data from your devices and uniform
              resource locator (URL) click stream data, including date and time,
              and content you viewed or searched for on any of our Services.
              This information is useful to us because it helps us to get a
              better understanding of how you use the Site and Services so that
              we can continuously provide you with the best user experience
              possible (e.g. by personalising the content that you see).
            </li>
            <li className="terms__info">
              <b>When you request any of our Services.</b> We may collect your
              personal information from you when you request any of our
              Services, including your name, contact details and any other
              information reasonably required in connection with your request
              for the Services.
            </li>
            <li className="terms__info">
              <b>When you use any of our Services.</b> We may collect the
              following types of information from you when you use any of our
              Services:
            </li>
            <ol style={abc}>
              <li className="terms__info">
                Basic contact information, such as your name, email address,
                postal address and telephone number;
              </li>
              <li className="terms__info">
                Any data you provide to us in respect of our delivery of the
                Services;
              </li>
              <li className="terms__info">
                Any data you submit or upload to the Site when you use the
                Services;
              </li>
              <li className="terms__info">
                Any correspondence we have with you should you contact us
                (including any comments or feedback you provide to us);
              </li>
              <li className="terms__info">
                Interests and communication preferences; and
              </li>
              <li className="terms__info">
                Any other data that you voluntarily submit through the Site or
                when you use the Services.
              </li>
            </ol>

            <li className="terms__info">
              <b>
                When your employer, institution, association or another
                authorised third party submits personal information about you.
              </b>{" "}
              We may collect your personal information from your employer,
              institution, education provider, association or another authorised
              third party (such as an entity that has hired you as a contractor
              under a contract for services) to the extent reasonably required
              to enable us to provide the Services.
            </li>
          </ol>
          <h4 style={noMargin} className="terms__header">
            Cookies and web beacons
          </h4>
          <div className="terms__info">
            Summit Institute may also automatically collect information through the use
            of cookies or similar technologies, such as web beacons. Cookies are
            small text files that a website sends to the browser on your
            computer or mobile device when you first visit a web page so that
            the website can recognise your device the next time you visit. Most
            websites typically use the following:
          </div>
          <ol>
            <li className="terms__info">
              Session cookies, which are temporary and deleted when you close
              your browser;
            </li>
            <li className="terms__info">
              Persistent cookies, which remain until you delete them or they
              expire; and
            </li>
            <li className="terms__info">
              Web beacons, which are electronic images, also known as
              single-pixel gifs.
            </li>
          </ol>
          <div className="terms__info">
            In general, cookies, web beacons and similar technologies do not
            contain personally identifiable information, but when you furnish
            your personal information through the Services, this information may
            be linked to the non-personally identifiable data stored in cookies
            sent to your browser from the Services.
          </div>
          <div className="terms__info">
            Summit Institute and its service providers use these technologies for
            various purposes, including:
          </div>
          <ol>
            <li className="terms__info">Facilitating the login process;</li>
            <li className="terms__info">Administering the Services;</li>
            <li className="terms__info">
              Customising and improving the Services;
            </li>
            <li className="terms__info">
              Personalising your browsing experience;
            </li>
            <li className="terms__info">
              Advertising, promotions and surveys;
            </li>
            <li className="terms__info">
              Paying royalties and licence fees to third-party providers, such
              as authors, societies and other copyright holders and content
              distributors;
            </li>
            <li className="terms__info">
              Tracking and analysing your preferences; and
            </li>
            <li className="terms__info">Tracking and analysing trends.</li>
          </ol>
          <div className="terms__info">
            There are a number of ways to manage cookies. The Help menu on most
            browsers tells you how to stop accepting cookies, how to be notified
            when you receive a new browser cookie, and how to disable existing
            browser cookies. However, if you block cookies, you may not be able
            to register, login or make full use of the Services. You can also
            use your mobile device’s settings to manage the available privacy
            options.
          </div>
          <div className="terms__info">
            Summit Institute’ HTML-formatted emails may contain web beacons to let it
            know whether its emails are opened and verify any clicks through to
            links or advertisements within the email. Summit Institute may use this
            information for purposes including determining which of its emails
            are more interesting to users, to query whether users who do not
            open Summit Institute’ emails wish to continue receiving them, and to
            inform Summit Institute’ advertisers in aggregate how many users have
            clicked on its advertisements. The web beacon will be deleted when
            you delete the email. Emails in plain text rather than HTML will not
            include the web beacon.
          </div>
          <h4 className="terms__header" style={noMargin}>
            How we use your personal information
          </h4>
          <div className="terms__info">
            We may use your personal information for the following purposes:
          </div>
          <ol>
            <li className="terms__info">
              <b>When you request any of our Services.</b> We may use your
              personal information to confirm your identity, contact you and
              otherwise perform the Services.
            </li>
            <li className="terms__info">
              <b>When you use any of our Services.</b> We may use your personal
              information to communicate with you about the Services and
              otherwise take any action in relation to the provision of the
              Services including:
            </li>
            <ol style={abc}>
              <li className="terms__info">
                to administer and manage your account;
              </li>
              <li className="terms__info">
                to allow you to upload, store and access your personal
                information and/or information about another third party who has
                given you permission to do so;
              </li>
              <li className="terms__info">
                to allow you to access the data that you are permitted to access
                as determined by the administrator of your account or by us (as
                the context requires);
              </li>
              <li className="terms__info">
                to respond to requests, enquiries, complaints or applications
                and to otherwise provide you with support to use our Services;
              </li>
              <li className="terms__info">
                to suggest to you better ways to use any of our Services based
                on information relating to your usage of the Services;
              </li>
              <li className="terms__info">
                to continuously improve our Services (including to improve the
                user experience related to any of our Services);
              </li>
              <li className="terms__info">
                to update you on relevant new features or benefits associated
                with the Services;
              </li>
              <li className="terms__info">
                to invite you to participate in surveys, competitions and
                similar promotions;
              </li>
              <li className="terms__info">
                to prevent and detect security threats, fraud or other malicious
                activity;
              </li>
              <li className="terms__info">
                to tell you when you need to take any action in relation to any
                of our Services, such as making a payment to us or sending us
                additional information.
              </li>
            </ol>
            <li className="terms__info">
              <b>When we market any of our Services to you.</b> We may use your
              personal information to contact you regarding our Services and to
              decide what marketing information to send to you. Please see below
              for more information including how to opt-out of marketing
              communications.
            </li>
            <li className="terms__info">
              <b>When we must meet our legal obligations.</b> We may need to use
              your personal information to comply with our legal obligations,
              resolve disputes and to meet any obligations at law or imposed by
              regulatory agencies.
            </li>
          </ol>
          <div className="terms__info">
            You may choose not to give us any of your personal information that
            we request. However, this may mean that we will be unable to
            properly provide you with the Services, and/or any information that
            you require from us.
          </div>
          <h4 style={noMargin} className="terms__header">
            Who we may share your personal information with
          </h4>
          <div className="terms__info">
            We may disclose, transfer or share your personal information in the
            following circumstances:
          </div>
          <ol>
            <li className="terms__info">
              <b>Our related agencies.</b> Your personal information may be
              accessible to any of our related agencies (being any agency that
              we have a majority shareholding in) to the extent that such
              disclosure is reasonably required to provide you with the
              Services.
            </li>
            <li className="terms__info">
              <b>Any agency with your consent.</b> We may share your personal
              information with agencies where you have consented or requested us
              to do so. This includes where you opt in to sharing your personal
              information with third party service providers through the
              Services for the purpose of delivering the Services.
            </li>
            <li className="terms__info">
              <b>An actual or potential buyer (and its agents and advisors).</b>{" "}
              We may disclose your personal data to an actual or potential buyer
              (and its agents and advisors) in connection with an actual or
              proposed purchase, merger or acquisition of any part of our
              business or of any part of our related agencies’ businesses.
            </li>
            <li className="terms__info">
              <b>Third party service providers.</b> Your personal information
              may be accessible to our service providers and suppliers, which
              assist us with producing and delivering our Services, operating
              our business, and marketing, promotion and communications. These
              providers and suppliers include, for example, editors, reviewers,
              credit card processors, customer support, email service providers,
              and IT service or hosting providers.
            </li>
            <li className="terms__info">
              <b>Employers and institutions.</b> If you access the Services
              through an employer, institution or association, any personal
              information and certain usage data gathered through the Services
              may be shared with your employer, institution or association for
              the purposes of using the Services (including account management,
              content management, learning management and for any other purpose
              in connection with the proper use of the Services).
            </li>
            <li className="terms__info">
              <b>Other third parties.</b> We may share personal information with
              other third parties if they expressly tell you about such
              potential disclosure at the point at which you submitted the
              information to them.
            </li>
            <li className="terms__info">
              <b>Our advisers.</b> We may share your personal information with
              any of our advisers who help us to manage our business, including
              professional services advisers such as accountants and lawyers and
              any other adviser whose assistance we reasonably require to manage
              our business.
            </li>
            <li className="terms__info">
              <b>Compliance with law and similar obligations.</b> We may share
              personal information to respond to or comply with any law,
              regulation, or court order, to investigate and help prevent
              security threats, fraud or other malicious activity, to enforce
              and protect the rights and properties of Summit Institute and/or its
              affiliates, and to protect the rights or personal safety of
              Summit Institute’ employees and third parties on or using its property.
            </li>
          </ol>
          <h4 style={noMargin} className="terms__header">
            Security
          </h4>
          <div className="terms__info">
            We’re committed to protecting your personal information and have
            appropriate technical and organisational measures in place to make
            sure that happens.
          </div>
          <div className="terms__info">
            Our hosting providers (AWS Amazon) implement strong physical access
            control checks to ensure that data is protected at its storage
            centres. Data is encrypted at rest.
          </div>
          <div className="terms__info">
            We ensure that only limited and authorised persons within Summit Institute
            have access to the underlying systems where data is stored. Users of
            our Services have their access controlled based on their permission
            levels.
          </div>

          <div className="terms__info">
            We employ logical separation to ensure that all Summit Institute
            infrastructure is deployed to maintain the privacy and security of
            data at different levels of application. This includes firewalls
            separating our database and the separation of our non-production
            environments.
          </div>

          <div className="terms__info">
            All usage is audited through log files and access audits which
            monitor which user accessed given information at a specific point in
            time and which user changed information (including the specific
            information which was changed).
          </div>

          <div className="terms__info">
            As the transmission of data via the internet cannot be assumed to be
            completely secure, we cannot guarantee the security of any data
            transmitted to our Site or Services. Accordingly, you remain
            responsible for any such transmission. Nonetheless, we will take all
            reasonable precautions and steps to ensure that data is transmitted
            as securely as possible. All data ingress or egress is transmitted
            via secure channels. All HTTP traffic is transmitted over TLS
            (HTTPS).
          </div>
          <h4 style={noMargin} className="terms__header">
            Privacy Breaches
          </h4>
          <div className="terms__info">
            A privacy breach occurs where there is unauthorised or accidental
            access to, or disclosure, alteration, loss, or destruction of,
            personal information held by us or an action that prevents us from
            accessing personal information on either a temporary or permanent
            basis.
          </div>

          <div className="terms__info">
            If we learn of a privacy breach involving personal information we
            hold, we will assess whether the privacy breach is likely to cause,
            or has caused, serious harm to an affected individual or
            individuals. If our assessment finds that the privacy breach has
            caused serious harm to an affected individual or individuals, or is
            likely to do so, we will take steps to minimise any harm, notify the
            affected individual or individuals (if required and permissible
            under the Privacy Act 2020) and notify the Privacy Commissioner
            within the timeframes prescribed by this Act.
          </div>
          <h4 style={noMargin} className="terms__header">
            Storage and Security of Data
          </h4>
          <div className="terms__info">
            We use third party service providers to assist in storing and
            processing certain types of personal information for us, and some of
            these service providers may be located overseas, or use facilities
            located overseas to provide us with services. These third-party
            service providers do not use personal information for any purpose
            other than to enable us to provide the Services.
          </div>
          <h4 style={noMargin} className="terms__header">
            Communications with you
          </h4>
          <div className="terms__info">
            You have the right to ask us not to use your personal information
            for marketing purposes. You can exercise your right to opt-out of
            our marketing communications by clicking “unsubscribe” when you
            receive a marketing email from us or contacting{" "}
            <b>geordie.wilson@summitinstitute.ac.nz.</b> If you do opt out, we may
            still need to send you operational communications (in connection
            with any request for, or use of, any of our Services) in accordance
            with this Privacy Policy.
          </div>
          <h4 style={noMargin} className="terms__header">
            How long do we keep your personal information for?
          </h4>
          <div className="terms__info">
            We will retain your personal information for as long as it is needed
            to be able to provide the Services to you. If we no longer provide
            any of our Services to you, we will only keep your personal
            information if it is necessary or required to meet legal or
            regulatory requirements, resolve disputes, or to prevent fraud or
            abuse.
          </div>
          <h4 style={noMargin} className="terms__header">
            General
          </h4>
          <ol>
            <b>Access to and accuracy of your personal information</b>
            <br />
            <div className="terms__info">
              You may request access to personal information about you that you
              have provided to us through the Site governed by this policy. You
              may also request to review and correct any of that personal
              information by contacting Summit Institute at the address listed below.
              We may charge a reasonable fee in relation to access and
              correction requests (in which case we will notify you of the fee
              in advance of actioning the request).
            </div>
            <b>Changes to Privacy Policy</b>
            <br />
            <div className="terms__info">
              If Summit Institute modifies this Privacy Policy, it will post the
              revised version here, with an updated revision date.
            </div>
            <b>Contacting Summit Institute</b>
            <br />
            <div className="terms__info">
              If you have comments, requests, questions or complaints about this
              Privacy Policy or Summit Institute’ processing of your information,
              please contact <b>geordie.wilson@summitinstitute.ac.nz.</b>
            </div>
          </ol>
        </div>
      </Layout>
    </div>
  )
}

export default Privacy
